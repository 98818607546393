define("discourse/plugins/discourse-landing-pages/discourse/templates/connectors/topic-category/topic-landing-page-info", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topic.landing_page_url}}
    <a
      href="/{{topic.landing_page_url}}"
      title="{{i18n 'topic.landing_page.link'}}"
      class="landing-page-url"
      target="_blank"
    >
      {{d-icon "file-alt"}}
      <span>{{i18n "topic.landing_page.link"}}</span>
    </a>
  {{/if}}
  */
  {
    "id": "qOOyOMwp",
    "block": "[[[41,[30,0,[\"topic\",\"landing_page_url\"]],[[[1,\"  \"],[44,[[28,[37,2],null,[[\"topic\"],[[28,[32,0],[\"topic\"],null]]]]],[[[10,3],[15,6,[29,[\"/\",[52,[30,1,[\"topic\"]],[28,[30,1,[\"topic\"]],null,null],[28,[32,1],[[30,0],\"topic.landing_page_url\",\"[\\\"The `topic` property path was used in the `discourse/plugins/discourse-landing-pages/discourse/templates/connectors/topic-category/topic-landing-page-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[15,\"title\",[29,[[28,[37,4],[\"topic.landing_page.link\"],null]]]],[14,0,\"landing-page-url\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[1,[28,[35,5],[\"file-alt\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,4],[\"topic.landing_page.link\"],null]],[13],[1,\"\\n  \"],[13]],[1]]],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-landing-pages/discourse/templates/connectors/topic-category/topic-landing-page-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\",\"a\",\"i18n\",\"d-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-landing-pages/discourse/templates/connectors/topic-category/topic-landing-page-info.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});